<template>
  <Cond :where="where" @refresh="refresh">
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
    >
      <el-table-column prop="name" label="名称" width="180"> </el-table-column>
      <el-table-column prop="host" label="IP地址" width="180"> </el-table-column>
      <el-table-column prop="database_name" label="数据库名" width="180"> </el-table-column>
      <el-table-column prop="user_name" label="数据账号" width="180"> </el-table-column>
      <el-table-column prop="db_code" label="数据编码" width="180"> </el-table-column>
      <el-table-column prop="table_code" label="表编码" width="180"> </el-table-column>
      <el-table-column prop="is_vip" label="独立服务器" width="180">
        <template slot-scope="scope">
          <div v-if="scope.row.is_vip == 1">
            <el-tag type="primary" effect="dark" size="small">独立服务器</el-tag>
          </div>
          <div v-else-if="scope.row.is_vip == 0">
            <el-tag type="success" effect="dark" size="small">普通服务器</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div>
            <el-button @click="openserver(scope.row)" type="text" size="small"
              >编辑</el-button
            >
          </div>
          <div>
            <el-popconfirm
              title="确定要删除吗？"
              @confirm="deleteserver(scope.row)"
            >
              <el-button type="text" size="small" slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
    <Serversform ref="servers" @refresh="loadList"></Serversform>
  </Cond>
</template>
<script>
import Cond from "./databasecond.vue";
import Serversform from "@/components/backend/system/servers.vue";
export default {
  components: {
    Cond,
    Serversform,
  },
  data() {
    return {
      url: {
        servers: "/sysadminsystem/servers",
        deleteserver: "/sysadminsystem/deleteserver",
      },
      where: {
        name: "",
        database_name: "",
        db_code:"",
        page: 1,
        pageSize: 50,
        total: 0,
        loading: false,
      },
      tableData: [
      ],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.servers, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    deleteserver: function (row) {
      this.where.loading = true;
      this.$rq.postAction(this.url.deleteserver, { id: row.id }).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.loadList();
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    openserver: function (row) {
      this.$refs.servers.show(row);
    },
  },
};
</script>