<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visiable"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
      size="small"
      label-position="top"
    >
      <el-form-item label="连接名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入连接名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="IP" prop="host">
        <el-input
          v-model="form.host"
          placeholder="请输入IP"
        ></el-input>
      </el-form-item>
      <el-form-item label="端口号" prop="port">
        <el-input
          v-model="form.port"
          placeholder="请输入端口号"
        ></el-input>
      </el-form-item>
      <el-form-item label="数据库名称" prop="database_name">
        <el-input
          v-model="form.database_name"
          placeholder="请输入数据库名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="数据库账号" prop="user_name">
        <el-input
          v-model="form.user_name"
          placeholder="请输入数据库账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="数据库密码" prop="password">
        <el-input
          v-model="form.password"
          placeholder="请输入数据库密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="字符集" prop="charset">
        <el-input
          v-model="form.charset"
          placeholder="请输入字符集"
        ></el-input>
      </el-form-item>
      <el-form-item label="最大连接数" prop="max_open_conns">
        <el-input-number
          v-model="form.max_open_conns"
          placeholder="请输入最大连接数"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="等待连接数" prop="max_idle_conns">
        <el-input-number
          v-model="form.max_idle_conns"
          placeholder="请输入等待连接数"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="最大生成时间" prop="max_life_time">
        <el-input-number
          v-model="form.max_life_time"
          placeholder="请输入最大生成时间"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="数据库编码" prop="db_code">
        <el-input
          v-model="form.db_code"
          placeholder="请输入数据库编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="表编码" prop="table_code">
        <el-input
          v-model="form.table_code"
          placeholder="请输入表编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="独立服务器" prop="is_vip">
        <el-switch
          v-model="form.is_vip"
          active-text="独立服务器"
          inactive-text="普通服务器"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="form.loading" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: "添加模块",
      visiable: false,
      form: this.getDefaultData(),
      rules: {
        name: [
          { required: true, message: "请输入连接名称", trigger: "blur" },
        ],
        host: [
          { required: true, message: "请输入IP地址", trigger: "blur" },
        ],
        port: [
          { required: true, message: "请输入端口号", trigger: "blur" },
        ],
        database_name: [
          { required: true, message: "请输入数据库名称", trigger: "blur" },
        ],
        user_name: [
          { required: true, message: "请输入数据库账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入数据库密码", trigger: "blur" },
        ],
        db_code: [
          { required: true, message: "请输入数据库编码", trigger: "blur" },
        ],
        table_code: [
          { required: true, message: "请输入表编码", trigger: "blur" },
        ],
      },
      url: {
        saveserver: "/sysadminsystem/saveserver",
      },
    };
  },
  created() {},
  methods: {
    getDefaultData: function () {
      return {
        id: 0,
        name: "",
        host: "",
        port: "3306",
        database_name:"",
        user_name:"",
        password:"",
        charset:"utf8mb4",
        max_open_conns:20,
        max_idle_conns:10,
        max_life_time:0,
        db_code:"",
        table_code:"",
        is_vip: 0,
        loading: false,
      };
    },
    handleClose: function () {
      this.$refs.form.resetFields();
      this.visiable = false;
    },
    show: function (row) {
      if (row) {
        this.title = "编辑数据库";
        this.form = this.$func.copy(row);
      } else {
        this.title = "添加数据库";
        this.form = this.getDefaultData();
      }
      this.visiable = true;
    },
    submit: function () {
      this.$refs.form.validate((v) => {
        if (v) {
          this.form.loading = true;
          this.$rq.postAction(this.url.saveserver, this.form).then((rs) => {
            this.form.loading = false;
            if (rs.code == 1) {
              this.visiable = false;
              this.$message({
                type: "success",
                message: rs.msg,
              });
              this.$emit("refresh");
            } else {
              this.$message({
                type: "warning",
                message: rs.msg,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.myview >>> .el-checkbox {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 8px;
}
.myview >>> .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
</style>